import React, {useEffect, useLayoutEffect} from "react"
import { Helmet } from "react-helmet"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import moment from "moment"
import { useMediaPredicate } from "react-media-hook"

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { HeadingBasic } from "../../components/heading/heading"
import {
  WpGroup,
  WpImagelistkey,
  Stats,
  Carousel,
} from "../../components/utils/render-functions"
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { Cover } from "../../components/cover/cover"
import { CoverMain } from "../../components/cover-main/cover-main"
import { CoverFeatured } from "../../components/cover-featured/cover-featured"
import { Card } from "../../components/card/card"
import { Container } from "../../components/grid/grid"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"

import Document from "../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

// sort by year in descending order
const compareElementYear = (a, b) => {
  const reportA = a.key
  const reportB = b.key

  let comparison
  if (reportA < reportB) {
    comparison = 1
  } else if (reportA > reportB) {
    comparison = -1
  }

  return comparison
}

const Reports = ({ sustainabilityReports }) => {
  let sustainabilityGroupByYear = sustainabilityReports && groupBy(sustainabilityReports, "company")
  // split annualGroupByYear to an array
  const sustainabilityArr =
    (sustainabilityGroupByYear &&
      Object.keys(sustainabilityGroupByYear).map(key => [
        key,
        sustainabilityGroupByYear[key],
      ])) ||
    []

  let documentItems = []

  sustainabilityArr.length > 0 &&
  sustainabilityArr.map((sustainabilityItem, i) => {
      let sustainabilityData = []
      sustainabilityItem[1].map(item => {
        sustainabilityData.push({
          title: item.node.title,
          linkTo:
            item.node.acfFile.file !== null &&
            item.node.acfFile.file.mediaItemUrl,
          fileSize:
            item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
          fileId:
            item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
          date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
            item.node.date
          ).format("HH:mm")}`,
        })
        return 1
      })

      documentItems.push(
        <div key={`${sustainabilityItem[0]}-${i}`} className="mb-5">
          <Document
            title={sustainabilityItem[0]}
            section="Sustainability Report"
            type="sustainability"
            data={sustainabilityData}
          />
        </div>
      )

      return 1
    })

  return (
    <section className="report">
      {/* {documentItems.length > 0 && documentItems.sort(compareElementYear)} */}
      {documentItems.length > 0 && documentItems}
      {documentItems.length === 0 && <div></div>}
    </section>
  )
}

const Sustainability = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgSustainability1: file(
        relativePath: { eq: "img_development-main.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "sustainability") {
          id
          title
          slug
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...CoreGroupBlock
            ...CoreButtonBlock
            ...AcfListImageBlock
            ...CoreEmbedYoutube
            ...AcfGalleryBlock
          }
          childPages(
            where: { in: [17668, 17687, 17719, 17703], orderby: { field: MENU_ORDER, order: ASC } }
          ) {
            nodes {
              title
              slug
              excerpt
              featuredImage {
                sourceUrl
                title
                caption
              }
              translation(language: ID) {
                title
                slug
                excerpt(format: RAW)
                featuredImage {
                  sourceUrl
                  title
                  caption
                }
              }
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...CoreGroupBlock
              ...CoreButtonBlock
              ...CoreEmbedYoutube
              ...AcfGalleryBlock
            }
          }
        }
        sustainabilityreport: fileCategory(id: "sustainability", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                date
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const wordPress = data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    (
      wordPress.find(x => x.name === "core/cover") === wordPress[0] ||
      wordPress.find(x => x.attributes.className === "cover-block") === wordPress[0]
    ) ?
    wordPress[0] : false

  const reportsPdf = data?.wordPress?.sustainabilityreport

  const sustainabilityGroupedByCompany =
    reportsPdf &&
    reportsPdf.filesPdf &&
    groupBy(reportsPdf.filesPdf.edges, "company")

  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const buttonScrollHandler = (target) => {
    var scrollContainer = target
    do { //find scroll container
      scrollContainer = scrollContainer.parentNode
      if (!scrollContainer) return
      scrollContainer.scrollTop += 1
    } while (scrollContainer.scrollTop == 0)
  
    var targetY = -60
    do { //find the top of target relatively to the container
      if (target == scrollContainer) break
      targetY += target.offsetTop
    } while (target = target.offsetParent)
  
    const scroll = function(c, a, b, i) {
      i++; if (i > 30) return
      c.scrollTop = a + (b - a) / 30 * i
      setTimeout(function(){ scroll(c, a, b, i); }, 20)
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0)
  }

  useEffect(() => {
    const thisElement = document.querySelectorAll('.img-wrapper-pillars .h-md-ratio-1-1')
    const galleryElement = document.querySelectorAll('.card-bg-img.h-ratio-2-1')
    const headerMobile = document.querySelector('.header-key-mobile')
    const cardCaption = document.querySelectorAll('.card-caption')
    if(resizeScreen){
      for (let i = 0; i < thisElement.length; i++) {
        if (thisElement[i].classList.contains("h-100px")) {
          thisElement[i].classList.remove("h-100px")
          thisElement[i].classList.add("h-300px")
        }
      }

      for (let i = 0; i < galleryElement.length; i++) {
        galleryElement[i].style.backgroundSize = `110px`
        galleryElement[i].style.marginBottom = `65px`
      }

      for (let i = 0; i < cardCaption.length; i++) {
        cardCaption[i].classList.add("text-center")
        cardCaption[i].style.color = `#333`
        cardCaption[i].style.backgroundColor = `transparent`
      }

      headerMobile.classList.remove("d-none")
      headerMobile.classList.add("text-center")
    }else{
      for (let i = 0; i < thisElement.length; i++) {
        if (thisElement[i].classList.contains("h-300px")) {
          thisElement[i].classList.remove("h-300px")
          thisElement[i].classList.add("h-100px")
        }
      }

      headerMobile.classList.add("d-none")
      headerMobile.classList.remove("text-center")
    }
  }, [])

  useLayoutEffect(() => {
    const currentUrl = location.href
    if(currentUrl.includes("#sus-report")){
      buttonScrollHandler(document.getElementById("sus-report"))
    }
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data?.imgCoverMain?.childImageSharp?.fluid
        }
        twitterImg={
          cover
            ? cover?.attributes?.url
            : data?.imgCoverMain?.childImageSharp?.fluid
        }
        img={
          cover
            ? cover?.attributes?.url
            : data?.imgCoverMain?.childImageSharp?.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          label="Committed to"
          h1={data?.wordPress?.pageBy?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="Sustainability"
          className="cover-md"
          videoUrl={cover.innerBlocks?.[0]?.attributes?.url}
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            if(item?.attributes?.content.includes("Key Performa")){
              if(resizeScreen){
                return(
                  <div key={`heading-${i}`} className="container text-center header-key-mobile">
                    <div className={i === 0 ? "py-main" : ""}>
                      <HeadingBasic
                        h2={item.attributes && item.attributes.content}
                      />
                    </div>
                  </div>
                )
              }else{
                return (
                  <div key={`heading-${i}`} className="container d-none header-key-mobile">
                    <div className={i === 0 ? "py-main" : ""}>
                      <HeadingBasic
                        h2={item.attributes && item.attributes.content}
                      />
                    </div>
                  </div>
                )
              }
            }else{
              return (
                <div key={`heading-${i}`} className="container">
                  <div className={i === 0 ? "py-main" : ""}>
                    <HeadingBasic
                      h2={item.attributes && item.attributes.content}
                    />
                  </div>
                </div>
              )
            }
          case "core-embed/youtube":
            return (
              <div className="py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  // type="video-popup"
                  videoSrc={item.attributes && item.attributes.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          case "core/buttons":
            if (
              item.innerBlocks[0].attributes.url &&
              item.innerBlocks[0].attributes.url.split(".").length > 1
            ) {
              return (
                <div key={`button=${i}`} className="container">
                  <div className={i === 0 ? "py-main" : "pb-main"}>
                    <HeadingBasic>
                      <a
                        href={item.innerBlocks[0].attributes.url}
                        target="_blank"
                        className="btn btn-link fx-underline"
                        rel="noopener noreferrer"
                        key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      >
                        {item.innerBlocks[0].attributes.text} &nbsp;
                        <i className="far fa-external-link"></i>
                      </a>
                    </HeadingBasic>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={`button-${i}`} className="container">
                  <Link
                    key={`${item.innerBlocks[0].attributes.url}-${i}`}
                    to={item.innerBlocks[0].attributes.url}
                    className="btn btn-link fx-underline"
                  >
                    {item.innerBlocks[0].attributes.text}
                  </Link>
                </div>
              )
            }
          case "core/paragraph":
            return <HeadingBasic data={[item.attributes.content]} />
          case "core/group":
            if(item.attributes?.className === "cover-block") return false;
            return (
              <div
                key={`${item.name}-${i}`}
                className={i === 0 || i === 1 ? "py-main" : "pb-main"}
              >
                <WpGroup data={item} />
              </div>
            )
          case "acf/acfgallery":
            if(resizeScreen){
              return (
                <Container
                  className={`gallery-icons mb-5`}
                  key={`gallery-${i}`}
                >
                  <Carousel data={item} />
                </Container>
              )
            }else{
              return(
                <div></div>
              )
            }
          case "acf/list-image":
            if(resizeScreen){
              return (
                <div></div>
              )
            }else{
              return (
                <div
                  key={`imagelistkey-${i}`}
                  className="container pb-main text-md-center"
                >
                  <WpImagelistkey data={item} variant={item.attributes?.className} />
                </div>
              )
            }
          case "acf/labelvalues":
            return (
              <div key={`${item.name}-${i}`} className="pb-main">
                <Stats data={item} />
              </div>
            )
          case "core/cover":
            if (i > 0) {
              return (
                <div key={`featured-${i}`} className="pb-main">
                  <CoverFeatured
                    label="Featured News"
                    img={
                      item.attributes.url || data.featured.childImageSharp.fluid
                    }
                    title={
                      item.innerBlocks[0] !== undefined &&
                      item.innerBlocks[0]?.attributes?.content
                    }
                    text={
                      item.innerBlocks[1] !== undefined &&
                      item.innerBlocks[1]?.attributes?.content
                    }
                    btnText={
                      item.innerBlocks[2] !== undefined &&
                      item.innerBlocks[2].innerBlocks &&
                      item.innerBlocks[2].innerBlocks[0].attributes.text
                    }
                    btnLink={
                      item.innerBlocks[2] !== undefined &&
                      item.innerBlocks[2].innerBlocks &&
                      item.innerBlocks[2].innerBlocks[0].attributes.url
                    }
                  />
                </div>
              )
            } else {
              return <div key={`${i}`}></div>
            }
          case "core/shortcode":
            if (item.attributes.text === "banner-subpage") {
              return (
                <section key={`banner-subpage-${i}`} className="pb-main">
                  <div className="row row-0">
                    {data.wordPress.pageBy.childPages.nodes?.slice(0, 4)?.map(
                      (subPage, i) => {
                        return (
                          <div key={`subpage-${i}`} className="col-md-3 img-wrapper-pillars">
                            <Card
                              variant="overlay"
                              title={subPage.title}
                              img={
                                subPage.featuredImage
                                  ? subPage.featuredImage.sourceUrl
                                  : data.imgSustainability1.childImageSharp
                                      .fluid
                              }
                              text={[
                                subPage.excerpt
                                  ? subPage.excerpt
                                  : "description"
                              ]}
                              link={
                                subPage.slug
                                  ? `/sustainability/${subPage.slug}`
                                  : "/"
                              }
                              imgHeight={`${resizeScreen ? "h-300px" : "h-100px" } h-md-ratio-1-1`}
                              className="card-sustainability"
                            />
                          </div>
                        )
                      }
                    )}
                  </div>
                </section>
              )
            } else if (item.attributes.text === "file-sustainability-recent") {
              return (
                <section
                  key={`sustainabity-report-${i}`}
                  className="py-main bg-gray-50"
                  id="sus-report"
                >
                  <div className="container ">
                    <h2 className="h2 mb-4 mb-md-5 text-md-center">Sustainability Reports</h2>
                    <Container>
                      <NavTabs
                        scrollable
                        className="pt-4"
                        data={[
                          {
                            name: "Sinarmas Land Limited",
                            target: "sinarmasland",
                          },
                          { name: "PT Bumi Serpong Damai Tbk",
                            target: "bsd",
                          },
                          {
                            name: "PT Duta Pertiwi Tbk",
                            target: "dutaPertiwi",
                          },
                        ]}
                      />
                    </Container>

                    {sustainabilityGroupedByCompany && (
                      <div>
                        <TabPane id="sinarmasland" className="py-lg-5 pt-5 pt-lg-0 active">
                          <Reports
                            sustainabilityReports={
                              sustainabilityGroupedByCompany &&
                              sustainabilityGroupedByCompany["Sinarmas Land Limited"] !== undefined &&
                              sustainabilityGroupedByCompany["Sinarmas Land Limited"]
                            }
                          />
                        </TabPane>
                        <TabPane id="bsd" className="py-lg-5 pt-5 pt-lg-0">
                          <Reports
                            sustainabilityReports={
                              sustainabilityGroupedByCompany &&
                              sustainabilityGroupedByCompany["PT Bumi Serpong Damai Tbk"]
                            }
                          />
                        </TabPane>
                        <TabPane id="dutaPertiwi" className="py-lg-5 pt-5 pt-lg-0">
                          <Reports
                            sustainabilityReports={
                              sustainabilityGroupedByCompany &&
                              sustainabilityGroupedByCompany["PT Duta Pertiwi Tbk"]
                            }
                          />
                        </TabPane>
                      </div>
                    )}
                  </div>
                </section>
              )
            }
            return <div key={`empty-${i}`}></div>
          default:
            return <div key={`default-${i}`}>{item.name}</div>
        }
      })}
    </Layout>
  )
}

export default Sustainability
